import React, { useEffect, useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined, AudioOutlined, PauseCircleOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';
import { Checkbox, Button } from 'antd';
import { Select, Tag } from 'antd';
import { useCookies } from 'react-cookie';

const { Option } = Select;

export default function ConfirmComponent(props) {
    const record = props.record;
    const reset = props.reset;

    const [gender, setGender] = useState(null);
    const [age, setAge] = useState(null);
    const [coughType, setCoughType] = useState(null);
    const [healthStatus, setHealthStatus] = useState(null);
    const [confirmValue1, setConfirmValue1] = useState(true);
    const [confirmValue2, setConfirmValue2] = useState(true);
    const [latestResult, setLatestResult] = useState(null);
    const [sending, setSending] = useState(false);
    const [userId, setUserId] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    useEffect(() => {
        if (reset) {
            setLatestResult(null);
            console.log("====== RESET ======");
        }
    }, [reset])

    useEffect(() => {
        console.log(cookies);
        if (cookies && cookies.user != null) {
            console.log(cookies);
            const tempUserInfo = cookies.user;
            if (tempUserInfo.uuid)
                setUserId(tempUserInfo.uuid);
            if (tempUserInfo.subject_gender != null)
                setGender(tempUserInfo.subject_gender === 'male' ? 1 : 0);
            if (tempUserInfo.subject_age)
                setAge(2021 - tempUserInfo.subject_age);
            if (tempUserInfo.subject_cough_type)
                setCoughType(tempUserInfo.subject_cough_type);
            if (tempUserInfo.subject_health_status)
                setHealthStatus(tempUserInfo.subject_health_status);
        }
    }, [cookies])

    const uploadRecord = () => {
        setSending(true);
        console.log("Upload Record: ", record);

        const uuid = "Med247_" + Date.now() + '' + gender.toString() + '' + (2021 - age);

        let metaData = {
            uuid: uuid,
            subject_gender: gender == 1 ? "male" : "female",
            subject_age: 2021 - age,
            subject_cough_type: coughType,
            subject_health_status: healthStatus
        };

        var file = new File([record.blob], "cough.wav", { lastModified: Date.now() });

        console.log("META: ", JSON.stringify(metaData));

        // var myHeaders = new Headers();
        // myHeaders.append('Access-Control-Allow-Origin', 'http://localhost:3000');
        // myHeaders.append('Access-Control-Allow-Credentials', 'true');

        let formData = new FormData();
        formData.append('audio_file', file);
        formData.append('meta', JSON.stringify(metaData));

        // fetch('http://localhost:3001/uploader', {
        // fetch('https://aicovidvnapi.med247.co/uploader', {
        fetch('https://engine-staging02.aicovidvn.org/api/predict/', {
            method: 'POST',
            body: formData,
            mode: 'no-cors'
        })
            .then(data => {
                // console.log(data.json());
                setUserId(uuid);
                setSending(false);
                setLatestResult(true);
                props.onUploadDone && props.onUploadDone();
                setCookie('user', metaData);
            });
    }

    const onSelectGender = (value) => {
        setGender(value);
    };

    const onSelectAge = (value) => {
        setAge(value);
    }

    const onSelectHealthStatus = (value) => {
        setHealthStatus(value);
    }

    const onSelectCoughType = (value) => {
        setCoughType(value);
    }

    var ages = [];
    for (var i = 2021; i > 1900; i--) {
        ages.push(i);
    }

    const confirm1 = (e) => { setConfirmValue1(e.target.checked) }

    const confirm2 = (e) => { setConfirmValue2(e.target.checked) }

    const restart = () => {
        setLatestResult(null);
        props.restart && props.restart();
        // if(cookies && cookies.user) {
        // removeCookie('user');
        // }
    }

    const finalResult = <div style={{ width: '100%' }}>
        {/* {
            latestResult ?
                <div style={{ width: '100%', backgroundColor: 'red', padding: '20px', color: 'white' }}>Bạn có nguy cơ mắc Covid 19</div>
                : <div style={{ width: '100%', backgroundColor: 'green', padding: '20px', color: 'white' }}>Bạn không có nguy cơ mắc Covid 19</div>
        } */}
        <p style={{ width: '100%', backgroundColor: 'green', padding: '20px', color: 'white' }}>Gửi tiếng ho thành công!</p>
        <p>Cảm ơn bạn đã đóng góp tiếng ho. Đây là mã UUID của bạn: <span style={{ color: 'red', fontWeight: 'bold' }}>{userId}</span>. Nếu bạn muốn nhận kết quả đánh giá Covid dựa vào tiếng ho bạn đã cung cấp, vui lòng điền thông tin UUID vào mẫu ở đường dẫn sau: <a target="_blank" href='https://bit.ly/request-covid-risk-evaluation'>https://bit.ly/request-covid-risk-evaluation</a>.</p>
        <Button type='primary' size='large' onClick={restart} style={{ marginTop: '20px' }} block>Thử lại</Button>
        {/* <Results uid={props.uid} /> */}
    </div>;

    return (
        <div style={{ width: '100%' }}>
            {
                latestResult == null ?
                    <div>
                        {
                            userId ? <div style={{ marginBottom: '10px' }}>User ID của bạn: <span style={{ fontWeight: 'bold' }}>{userId}</span></div> : null
                        }
                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'row', marginBottom: '20px', flexWrap: 'wrap' }}>
                            <div className='ComboBoxContainer'>
                                <span className='SelectLabel'>Giới tính:</span>
                                <Select
                                    onChange={onSelectGender}
                                    // className='ComboBox'
                                    style={{ width: 120, backgroundColor:'mintcream', textAlign:'left'  }}
                                    placeholder="Giới tính"
                                    value={gender}
                                >
                                    <Option value={1}>Nam</Option>
                                    <Option value={0}>Nữ</Option>
                                </Select>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '4px' }}>
                                <span className='SelectLabel'>Năm sinh:</span>
                                <Select
                                    // className='ComboBox'
                                    showSearch
                                    style={{ width: 120, backgroundColor:'mintcream', textAlign:'left'  }}
                                    placeholder="Năm sinh"
                                    optionFilterProp="children"
                                    onChange={onSelectAge}
                                    value={age}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        ages.map((item) => {
                                            return <Option value={item}>{item.toString()}</Option>;
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '4px' }}>
                                <span className='SelectLabel'>Tại sao bạn ho:</span>
                                <Select
                                    onChange={onSelectCoughType}
                                    // className='ComboBox'
                                    style={{ width: 160, backgroundColor:'mintcream', textAlign:'left'  }}
                                    placeholder="Lý do bạn ho"
                                    value={coughType}
                                >
                                    <Option value='force_cough'>Chủ động ho</Option>
                                    <Option value='unknown'>Không rõ</Option>
                                    <Option value='undesirable_cough'>Ho do bệnh</Option>
                                </Select>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', margin: '4px' }}>
                                <span className='SelectLabel'>Sức khoẻ hiện tại:</span>
                                <Select
                                    onChange={onSelectHealthStatus}
                                    // className='ComboBox'
                                    style={{ width: 170, backgroundColor:'mintcream', textAlign:'left' }}
                                    placeholder="Sức khoẻ hiện tại"
                                    value={healthStatus}
                                >
                                    <Option value='F0'>F0</Option>
                                    <Option value='F1'>F1</Option>
                                    <Option value='F2'>F2</Option>
                                    <Option value='F3'>F3</Option>
                                    <Option value='F4'>F4</Option>
                                    <Option value='normal_health'>Khoẻ mạnh</Option>
                                    <Option value='common_cold'>Cúm thường</Option>
                                    <Option value='asthma'>Hen xuyễn</Option>
                                </Select>
                            </div>
                        </div>
                        {/* <div style={{ paddingTop: '20px', textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                            <span style={{ marginBottom: '20px' }}>Đây là nguồn tư liệu quý giá, giúp chúng tôi đánh giá tác động của Covid đến tiếng ho của chúng ta. Ở giai đoạn khởi đầu này, chúng tôi tin rằng mình sẽ mắc nhiều sai sót trong dự đoán. Nhưng việc đón nhận thêm các thông tin và chia sẻ từ cộng đồng sẽ giúp cho việc dự đoán các sự thay đổi trong tiếng ho của con người sẽ được bước đột phát trong tương lai.</span>
                            <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
                                <Checkbox onChange={confirm1} checked={confirmValue1} />
                                <span style={{ marginLeft: '10px' }}>Chúng tôi không chịu trách nhiệm đối với kết quả dự đoán từ máy tính. </span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'start', marginTop: '5px' }}>
                                <Checkbox onChange={confirm2} checked={confirmValue2} />
                                <span style={{ marginLeft: '10px' }}>Kết quả dự đoán không nên được dùng trong việc đưa ra các quyết định chẩn đoán và chữa trị trong y tế. </span>
                            </div>
                        </div> */}

                        <Button onClick={uploadRecord} type='primary' size='large' disabled={gender == null || age == null || sending || (record == null)} loading={sending} icon={<UploadOutlined />}>GỬI TIẾNG HO</Button>
                    </div>
                    : finalResult
            }

        </div >
    );
}