import firebase from "firebase/app";
import "firebase/firestore";


var firebaseConfig = {
    apiKey: "AIzaSyB-PpIQ3MKsj62updsa7G9pJZe2-sByXzI",
    authDomain: "coughai.firebaseapp.com",
    projectId: "coughai",
    storageBucket: "coughai.appspot.com",
    messagingSenderId: "1035459173932",
    appId: "1:1035459173932:web:56046efb814a412ce43b89"
  };
  // Initialize Firebase
  const fb = firebase.initializeApp(firebaseConfig);
  const db = fb.firestore();

  export {fb, db};
