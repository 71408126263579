import logo from './logo.svg';
import cover from './cover.jpg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { DownOutlined, AudioOutlined, PauseCircleOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { Button } from 'antd';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import ReactAudioPlayer from 'react-audio-player';
import ConfirmComponent from './Confirm';
import './firebase';
import { CookiesProvider, useCookies } from 'react-cookie';


function App() {
  const [numberId, setNumberId] = useState('1234354322');
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [seconds, setSeconds] = useState(0);
  const [recrodTime, setRecordTime] = useState(0);
  const [recordHo, setRecordHo] = useState(null);
  const [doneStop, setDoneStop] = useState(false);
  const [wantRestart, setWantRestart] = useState(false);
  const [hadRecord, setHadRecord] = useState(false);
  const [record, setRecord] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['uuid']);

  useEffect(() => {
    console.log("DONE STOP: ", doneStop);
    if (doneStop && wantRestart) {
      console.log("START AGAIN: ", doneStop);
      start();
    }
  }, [doneStop])

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (recordState == RecordState.START) {
      setRecordTime(recrodTime => recrodTime + 1);
    }
    if (recrodTime > 4) {
      stop();
    }
  }, [seconds]);

  const recordAgain = () => {
    setHadRecord(false);
    setRecordHo(null);
    setRecordState(RecordState.NONE);
  }

  const restart = () => {
    setHadRecord(false);
    setWantRestart(true);
    setRecordHo(null);
    if (recordState == RecordState.NONE || recordState == RecordState.STOP)
      start();
    else {
      stop();
    }
  }

  const start = () => {
    setRecordState(RecordState.START);
    setDoneStop(false);
    setWantRestart(false);
  }

  const stop = () => {
    setRecordState(RecordState.STOP);
    setSeconds(0);
    setRecordTime(0);
  }

  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    console.log('audioData', audioData)
    setDoneStop(true);
    if (!wantRestart) {
      setRecordHo(audioData.url);
      setRecord(audioData);
    }
  }

  const recordComponent = () => {
    return (
      <CookiesProvider>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ textAlign: 'left', width: '100%' }}>
            <p style={{ fontSize: '10pt' }}><span style={{ color: 'blue', fontWeight: 'bold' }}>AI<span style={{ color: 'red' }}>COVID</span>VN</span> là 1 dự án về AI do hàng trăm chuyên gia công nghệ cùng hợp tác xây dựng để dự đoán Covid19 qua tiếng ho. </p>
            <p style={{ fontSize: '10pt' }}>Để AI có thể học và phân tích, dự đoán chính xác hơn, chúng tôi cần thu thập thêm thật nhiều tiếng ho từ các bạn (Dù bạn đã dương tính hay đang khoẻ mạnh). </p>
            <h3>2 bước đơn giản để góp sức đẩy lùi Covid:</h3>
          </div>
          <div className='Step'> BƯỚC 1: Ghi âm tiếng ho trong 5 giây </div>
          <span style={{ fontSize: '9pt', fontStyle: 'italic', textAlign: 'left', color: 'red' }}>Đảm bảo chắc chắn rằng bạn không đứng quá gần bất kì ai khi ho</span>

          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', maxWidth: '680px', width: '100%' }}>
            <div style={{ backgroundColor: 'black', width: '100%', marginTop: '10px' }}>
              <AudioReactRecorder type="audio/wav" state={recordState} onStop={onStop} style={{ width: '100%', backgroundColor: 'blue' }} canvasHeight={60} canvasWidth='width:100%' backgroundColor='black' foregroundColor='lightblue' />
            </div>
            {
              recordHo ?
                <ReactAudioPlayer
                  src={recordHo}
                  autoPlay
                  controls
                  style={{ width: '100%', borderRadius: '0px' }}
                /> : null
            }
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px', marginBottom: '20px' }}>
              {
                recordState == RecordState.NONE ?
                  <Button type="primary" icon={<AudioOutlined />} size="large" onClick={start}> Bắt đầu ghi âm </Button>
                  : ((recordState == RecordState.START || recordState == RecordState.PAUSE) ?
                    <Button disabled type="primary" icon={<AudioOutlined />} size="large" onClick={start}> Đang ghi âm {recrodTime}s </Button>
                    : <Button type="primary" icon={<ReloadOutlined />} size="large" onClick={restart}> Thu âm lại </Button>)
              }

            </div>
          </div>
        </div>
      </CookiesProvider>
    );
  }

  const nextStep = () => {
    setHadRecord(true);
  }

  const onUploadDone = () => {
    setHadRecord(true);
  }

  return (
    <div className="App">
      <div style={{
        maxHeight: '300px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
      }} >
        <img src={cover} style={{ width: '100%', height: 'fit-content', alignSelf: 'center' }} />
      </div>
      <div className="Container">
        <div className="TopHeader">
          <h2>Dự án "Dự đoán Covid qua tiếng ho"</h2>
          {/* <span style={{ fontSize: '13pt', margin: 0 }}>Dự án "Dự đoán Covid qua tiếng ho"</span> */}
          {/* <div className='numberId'>ID: <span className='NumberValue'>{numberId}</span></div> */}
        </div>
        {recordComponent()}

        <div className='Step'> BƯỚC 2: Nhập thông tin về tiếng ho</div>
        <ConfirmComponent record={record} uid={numberId} restart={recordAgain} reset={!hadRecord} onUploadDone={onUploadDone} />
        {/* <Button type="primary" icon={<UploadOutlined />} size="large" onClick={nextStep}> Gửi tiếng ho </Button> */}
      </div>
      <div style={{ padding: '10px', backgroundColor: 'black', color: 'white', position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
        Một sản phẩm hợp tác giữa Med247 và AICOVIDVN - Powered by Med247 @CopyRight 2021
      </div>
    </div>
  );
}

export default App;
